<template>
    <section class="ftco-section bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="wrapper">
                        <div class="row no-gutters">
                            <div class="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                <div class="contact-wrap w-100 p-md-5 p-4">
                                    <h1 class="mb-4">I Want to Share</h1>
                                    <div class="mb-4">
                                        Kindly post the item you want to share here... And get to help people you never meet...
                                    </div>
                                    <form @submit.prevent="submitShareForm" name="contactForm" class="contactForm" enctype='multipart/form-data'>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="label" for="name">Item Name</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name="name" 
                                                        id="name" 
                                                        placeholder="Example: T-Shirts"
                                                        v-model="record.name"
                                                        required
                                                        maxlength="50"
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-md-6"> 
                                                <div class="form-group">
                                                    <label class="label" for="email">Email Address</label>
                                                    <input 
                                                        type="email" 
                                                        class="form-control" 
                                                        name="email" 
                                                        id="email" 
                                                        placeholder="Email"
                                                        v-model="record.email"
                                                        readonly
                                                    >
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="label" for="name">Brief Description (Optional)</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        name="name" 
                                                        id="name" 
                                                        placeholder="Example: The T-shirt size 12....."
                                                        v-model="record.description"
                                                        maxlength="150"
                                                    >
                                                </div>
                                            </div> 
                                            
                                            <div class="col-md-6" v-if="countries && countries.length > 0">
                                                <div class="form-group">
                                                    <label class="label" for="subject">Country</label>
                                                    <select class="form-control" v-model="record.country" required @change="changeCountry">
                                                        <option :value="null" class="text-center" disabled>--- Select Country ---</option>
                                                        <option 
                                                            v-for="country in countries"
                                                            :key="country.id"
                                                            :value="country.id"
                                                        > {{ country.name }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-6" v-if="cities && cities.length > 0">
                                                <div class="form-group">
                                                    <label class="label" for="subject">Cities</label>
                                                    <select class="form-control" v-model="record.city" required>
                                                        <option :value="null" class="text-center" disabled>--- Select City ---</option>
                                                        <option 
                                                            v-for="city in cities"
                                                            :key="city.id"
                                                            :value="city.name"
                                                        > {{ city.name }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="label" for="name">WhatsApp Number (Optional)</label>
                                                    <vueye-phone-input 
                                                        v-model="phone" 
                                                        outlined id="phone"  
                                                        placeholder="8067321XXXX" 
                                                        class="form-control high-z-index" 
                                                        @click-dropdown="selected"
                                                    />
                                                </div>
                                            </div>
                                                                                         
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="label" for="name">Upload Item Images (JPG, JPEG, PNG Files only)</label>
                                                    <Uploader
                                                        :server="uploadItemImageUrl"
                                                        :media="media.saved"
                                                        path="/storage/media"
                                                        id="images"
                                                        :max="3"
                                                        :maxFilesize="2.0"
                                                        @add="addMedia"
                                                        @remove="removeImage"
                                                        reqiured
                                                    />
                                                </div>
                                            </div>  
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input 
                                                        type="submit" 
                                                        :value="(this.isloading ? 'Please Wait...' : 'Share Item')" 
                                                        class="btn btn-primary"
                                                    >
                                                    <!-- <div class="submitting"></div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5 d-flex align-items-stretch" v-if="params">
                                <div class="info-wrap bg-primary w-100 p-md-5 p-4">
                                    <h3>Let's get in touch</h3>
                                    <p class="mb-4">We're open for any suggestion or just to have a chat</p>
                                    <div class="dbox w-100 d-flex align-items-start">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="fa fa-map-marker"></span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span>Address:</span> {{ params.address }}</p>
                                        </div>
                                    </div>
                                    <div class="dbox w-100 d-flex align-items-center">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="fa fa-phone"></span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span>Phone:</span> <a :href="'tel:'+params.phone">{{ params.phone }}</a></p>
                                        </div>
                                    </div>
                                    <div class="dbox w-100 d-flex align-items-center">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="fa fa-paper-plane"></span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span>Email:</span> <a :href="'mailto:'+params.email">{{ params.email }}</a></p>
                                        </div>
                                    </div>
                                    <div class="dbox w-100 d-flex align-items-center">
                                        <div class="icon d-flex align-items-center justify-content-center">
                                            <span class="fa fa-whatsapp"></span>
                                        </div>
                                        <div class="text pl-3">
                                            <p><span>WhatsApp: </span> 
                                                <a 
                                                    :href="'https://api.whatsapp.com/send?phone='+ params.whatsapp"
                                                    target="_blank"
                                                >
                                                    {{ params.whatsapp }}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12">
                    <div id="map" class="map"></div>
                </div> -->
            </div>
        </div>
    </section>

</template>

<script>
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";
    import { mapActions, mapGetters } from 'vuex';
    // import Autocomplete from 'vuejs-auto-complete'
    import countryData from '../../../countries.json';
    import VueyePhoneInput from "vueye-phone-input";
    import Uploader from "vue-media-upload";
    import states from '../../../states.json';

    export default {
        name: 'ShareForm',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        props: ['params', 'userEmail', 'uploadItemImageUrl'],
        components: {
            // Autocomplete
            VueyePhoneInput,
            Uploader
        },
        data() {
            return {
                countries: countryData,
                record: {
                    name: null,
                    email: this.userEmail,
                    country: null,
                    city: null,
                    whatsapp: null,
                    description: null
                },
                phone: {
                    number: "",
                    nationalNumber: "",
                    isValid:false
                },
                isloading: false,
                media: {
                    saved: [],
                    added: [],
                    removed: []
                },
                states: states,
                cities: [],
                selectedCountry: null
            }
        },
        computed: {
            ...mapGetters(['recaptchaKey'])
        },
        methods: {
            ...mapActions(['removeItemImage', 'publishUserItem']),
            addMedia(addedImage, addedMedia){
                this.media.added = addedMedia
            },
            removeImage(image, removedMedia) {
                if(image && image.name) {
                    this.preloader();
                    this.removeItemImage({ name: image.name }).then((res) => {// remove image
                            if(res.status) { // set data
                                this.messageAlert('Image was deleted from Server', 'success');
                            } else {
                                this.messageAlert('Error occurred while removing image from Server...', 'error');
                            }
                    }).
                    catch(() => {
                        this.messageAlert('Error occurred while removing image from Server...', 'error');
                    });
                }
                this.media.removed = removedMedia
            },
            selected() {
                if(this.phone.isValid) {
                    this.record.whatsapp = this.phone.number;
                } else {
                    this.messageAlert("Select the Country Code", "error");
                }
            },
            changeCountry(e) {// update cities
                this.cities = [];
                this.selectedCountry = null;
                let id = e.target.value;
                this.selectedCountry = this.countries.filter(item => item.id == id)[0];
                this.cities = this.states.filter(item => item.country_id == id);
            },
            submitShareForm() {  
                let check = this.validateInputs();// validate inputs
                if(check) {
                    grecaptcha.execute(this.recaptchaKey , {action: 'submit'}).then((token) => {
                        return this.publishItem(token);// publish item 
                    });
                }                
            },
            publishItem(token) {
                this.isloading = true;
                this.preloader();// show loading
                this.record.token = token;
                this.record.images = this.media.added;// add files
                this.record.country = this.selectedCountry ? this.selectedCountry.name : null;
                this.publishUserItem(this.record).then((res) => {// get homepage data
                    this.isloading = false;
                    this.emptyRecord();
                    if(res.status) {
                        this.messageAlert(res.msg, 'success');
                        setTimeout(() => { window.location.href='/items'; }, 3000)
                    } else {
                        this.messageAlert(res.msg, 'error');
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msg = 'Error occurred while publishing item, kindly reload page and try again...';
                    this.messageAlert(msg, 'error');
                });
            },
            validateInputs() {
                let data = this.record;
                let inputs = { name: null, country: null, city: null };
                let files = this.media.added;
                files.length > 0 ? true : this.messageAlert('No File(s) selected', 'warning');
                inputs.city = data.city ? data.city : this.messageAlert('Invalid City', 'warning');
                inputs.country = data.country ? data.country : this.messageAlert('Invalid Country', 'warning');
                inputs.name = data.name ? data.name : this.messageAlert('Invalid Name', 'warning');
                return inputs.name && inputs.country && inputs.city && files.length > 0 ? true : false;
            },
            emptyRecord() {
                this.record = {
                    name: null,
                    email: this.userEmail,
                    country: null,
                    city: null,
                    whatsapp: null,
                    description: null
                };
                this.media = {
                    saved: [],
                    added: [],
                    removed: []
                };
            },
            messageAlert(text, icon) {
                const Toast = swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = swal.stopTimer;
                        toast.onmouseleave = swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: icon,
                    title: text
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Uploading Item...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    // timerProgressBar: true,
                    willOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .high-z-index {
        z-index: 1000 !important;
    }



    .countries-cities {
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;
        .select-box {
            height: 40px;
            min-width: 300px;
            background: #fff;
            border: 1px solid #e9e9e9;
            position: relative;
            select {
            background: none;
            border: none;
            outline: none;
            padding-left: 7px;
            padding-right: 65px;
            appearance: none;
            width: 100%;
            height: 100%;
            }
            svg {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%;
            background: darken(#fff, 3%);
            pointer-events: none;
            box-sizing: border-box;
            padding: 5px;
            path {
                fill: rgba(black, .7);
            }
            }
        }
    }
</style>