<template>
    <div>
        
        <div 
            class="container"
            v-if="records && records.length > 0"
        >
            <div class="row">
                <div 
                    class="col-md-4 d-flex services align-self-stretch px-4 ftco-animated"
                    v-for="record in records"
                    :key="record.id"
                    style="margin-bottom: 20px;"
                >
                    <div class="d-block services-wrap text-center">
                        <div 
                            v-if="record.image"
                            class="img" 
                        >
                            <img class="img" :src="record.image.url" />
                        </div>
                        <div class="media-body p-2 mt-3">
                            <div class="meta mb-2">
                                <div><a href="javascript:void(0)">{{ formatTime(record.created_at) }}</a></div>
                                <div>
                                    <a href="javascript:void(0)" class="meta-chat text-capitalize">
                                        <span v-if="(record.status == 'open')">
                                            <span class="fa fa-folder-open-o"></span>
                                            Get {{ record.name }}
                                        </span>  
                                        <span v-if="((record.status == 'engaged') || (record.status == 'in-transit'))">
                                            <span class="fa fa-handshake-o"></span> {{ replaceAll(record.status, '-', ' ') }}
                                        </span>                                             
                                    </a>
                                </div>
                            </div>

                            <h3 class="heading">{{ record.name }}</h3>
                            <!-- <p>{{ record.description }}</p> -->
                            <br>
                            <p>
                                <router-link 
                                    :to="'/item/details/' + record.id" 
                                    class="btn btn-primary btn-outline-primary"
                                >
                                    Read more
                                </router-link>
                            </p>
                        </div>
                    </div>  
                </div>
            </div>

            <LoadMorePage 
                :pagination="pagination"
                :paginate="paginate"
                :isLoading="isLoading"
            />
        </div>
            
    </div>
</template>

<script>
    // import Pagination from '../Pagination.vue'
    import LoadMorePage from '../LoadMorePage.vue'
    import moment from 'moment'

    export default {
        name: 'ItemsDisplayPage',
        props: ['records', 'pagination', 'paginate', 'isLoading'],
        components: {
            LoadMorePage
            // Pagination
        },
        methods: {
            formatTime(date) {
                return moment(date).format("MMMM Do, YYYY");
            },
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>