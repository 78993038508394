<template>
    <section class="ftco-section ftco-no-pt ftco-no-pb bg-light" v-if="record">
        <div class="container">
            <div class="row no-gutters">
                <div 
                    v-if="record.image"
                    class="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" 
                    :style="'background-image: url('+record.image.url+');'"
                ></div>
                <div class="col-md-7 wrap-about py-4 py-md-5 ftco-animate">
                    <div class="heading-section mb-5">
                        <div class="pl-md-5">
                            <span class="subheading mb-2">
                                Welcome to {{ getsiteName }}
                            </span>
                            <h2 class="mb-2">{{ record.description }}</h2>
                        </div>
                    </div>
                    <div class="pl-md-5">
                        <span v-html="record.content"></span>
                        <!-- <div class="founder d-flex align-items-center mt-5" v-if="record.image">
                            <div 
                                class="img" 
                                :style="'background-image: url('+record.image.url+');'"
                            ></div>
                            <div class="text pl-3">
                                <h3 class="mb-0">Cythia Hunter</h3>
                                <span class="position">Personal Dietitian</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'About',
        props: ['record'],
        computed: {
            ...mapGetters(['getsiteName'])
        }
    }
</script>

<style lang="scss" scoped>

</style>