import { createRouter, createWebHistory } from 'vue-router'
import Store from './store'
import Homepage from './components/pages/Homepage'
import OtherContentPage from './components/pages/OtherContentPage'
import MenuPage from './components/pages/MenuPage'
import OtherContentCategory from './components/pages/OtherContentCategory'
import OtherContentSinglePage from './components/pages/OtherContentSinglePage'
import ContactPage from './components/pages/ContactPage'
import SubMenuRoutePage from './components/pages/SubMenuRoutePage'
import MenuRoutePage from './components/pages/MenuRoutePage'
import SharePage from './components/pages/SharePage'
import LoginPage from './components/auth/LoginPage.vue'
import SharedItemsPage from './components/pages/SharedItemsPage.vue'
import SharedItemDetailsPage from './components/pages/SharedItemDetailsPage.vue'
import DashboardPage from './components/pages/DashboardPage.vue'
import LogoutPage from './components/auth/LogoutPage.vue'

const routes = [
    { 
        path: '/', 
        name: 'Homepage', 
        component: Homepage, 
        meta: {
            requiresVisitor: true
        }
    },    
    { 
        path: '/privacy-policy', 
        name: 'privacy-policy', 
        component: OtherContentSinglePage,
        meta: {
            requiresVisitor: true
        } 
    },
    { 
        path: '/contact', 
        name: 'contact', 
        component: ContactPage,
        meta: {
            requiresVisitor: true
        }
    },
    { 
        path: '/share', 
        name: 'SharePage', 
        component: SharePage,
        meta: {
            requiresAuth: true
        }
    },
    { 
        path: '/dashboard', 
        name: 'Dashboard', 
        component: DashboardPage,
        meta: {
            requiresAuth: true
        }
    },
    { 
        path: '/logout', 
        name: 'Logout', 
        component: LogoutPage,
        meta: {
            requiresAuth: true,
            disallowAuthed: true
        }
    },
    { 
        path: '/login', 
        name: 'Login Page', 
        component: LoginPage,
        meta: {
            requiresVisitor: true
        } 
    },
    { 
        path: '/items', 
        name: 'Shared Items', 
        component: SharedItemsPage,
        meta: {
            requiresVisitor: true
        } 
    },
    { 
        path: '/item/details/:slug', 
        name: 'Shared Item Details', 
        component: SharedItemDetailsPage,
        meta: {
            requiresVisitor: true
        } 
    },
    { 
        path: '/pages/:category/:slug', 
        name: 'OtherContentPage', 
        component: OtherContentPage,
        meta: {
            requiresVisitor: true
        }
    },
    { 
        path: '/menu/:category/:slug', 
        name: 'MenuPage', 
        component: MenuPage,
        meta: {
            requiresVisitor: true
        }
    },
    { 
        path: '/category/:slug', 
        name: 'OtherContentCategory', 
        component: OtherContentCategory,
        meta: {
            requiresVisitor: true
        }
    },
    { 
        path: '/:menu/:slug', 
        name: 'sub-menu-page', 
        component: SubMenuRoutePage,
        meta: {
            requiresVisitor: true
        } 
    },
    { 
        path: '/:slug', 
        name: 'menu-page', 
        component: MenuRoutePage,
        meta: {
            requiresVisitor: true
        } 
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
}); 


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let saved = localStorage.getItem('time_out');// set access token
        let lockTime = localStorage.getItem('lock_time');// get lock time
        if(! lockTime) {// initiate profile lock function
            let hours = 3;
            localStorage.setItem('lock_time', new Date().getTime() + (hours * 60 * 60 * 1000));// set lock time
        }
        if (saved && (saved < new Date().getTime())) {//session time out
            localStorage.clear();
            window.location.reload();
        }
        if (! Store.getters.isLoggedIn) {
            next({
            path: '/login'
            })
        } else {
            next()
        }
    } else {
      next()
    }
  });

export default router;
