import axios from 'axios';

const state = {
    
};

const getters = {
};

const actions = {
    async getMenuPage(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getMenuPageUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getOtherContentPages(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getOtherContentPagesUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getMainMenuContent(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getMainMenuContentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getMainMenuContents(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getMainMenuContentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getPaginationRecords(state, url) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response;
    },
    async getOtherContentSinglePage(state, data) {// get other content page
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getOtherContentSinglePageUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async updateContact(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateContactUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getSubMenuPageContent(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSubMenuPageContentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getMenuRoutePageContent(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getMenuRoutePageContentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async sendQuote(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.sendQuoteUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getSharedItems(state, data) {// get shared items
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSharedItemsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getSharedItemDetails(state, data) {// get shared items
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getSharedItemDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
    async getUserSharedItems(state, data) {// get shared items
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserSharedItemsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}