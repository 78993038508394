<template>
    <section class="ftco-section ftco-services" v-if="items && items.length > 0">
    	<div class="container">
    		<div class="row ftco-animate">
            <div class="col-md-12">
                <div class="carousel-testimony owl-carousel">
                    <div 
                      class="item"
                      v-for="item in items"
                      :key="item.id"
                    >
                        <div class="d-flex services align-self-stretch px-4 ftco-animate">
                          <div class="d-block services-wrap text-center"> 
                            <router-link 
                              :to="'/item/details/' + item.id"
                            >
                              <!-- <div 
                                v-if="item.image"
                                class="img" 
                                :style="'background-image: url(' + item.image.url + ');'"
                              ></div> -->
                              <img class="img" :src="item.image.url" />
                            </router-link>
                            
                            <div class="media-body p-2 mt-3">
                              <router-link 
                                :to="'/item/details/' + item.id"
                              >
                                <h3 class="heading">{{ item.name }}</h3>
                                <p>{{ item.description }}</p>
                                <div class="meta mb-2">
                                  <div>
                                    
                                      Shared on {{ formatTime(item.created_at) }}
                                    
                                  </div>
                                  <div>
                                      <router-link 
                                        :to="'/item/details/' + item.id"
                                        class="meta-chat"
                                      >
                                        <span v-if="(item.status == 'open')">
                                            <span class="fa fa-folder-open-o"></span> {{ item.status }}
                                        </span>  
                                        <span v-if="(item.status == 'engaged')">
                                            <span class="fa fa-handshake-o"></span> {{ item.status }}
                                        </span>                                         
                                      </router-link>
                                  </div>
                                  <!-- <div><router-link to="/" class="meta-chat"><span class="fa fa-comment"></span> 3</router-link></div> -->
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                    </div>                    
                </div>                
            </div>
            <div class="col-md-12 text-center">
              <router-link to="/items" class="btn btn-outline-primary">View More</router-link>
            </div>
        </div>
    	</div>
    </section>
</template>

<script>
  import moment from 'moment'
  
  export default {
      name: 'Services',
      props: ['items'],
      computed: {
          products() {// get four services
              return this.services && this.services.contents ? this.services.contents.slice(0, 4) : [];
          }
      },
      methods: {
        formatTime(date) {
          return moment(date).format("MMMM Do, YYYY");
        }
      }
  }
</script>

<style lang="scss" scoped>

</style>