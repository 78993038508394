<template>
    <section class="ftco-section ftco-no-pb bg-light" v-if="contents && contents.length > 0">
      <div class="container">
        <div class="row justify-content-center pb-5 mb-3">
          <div class="col-md-7 heading-section text-center ftco-animate">
          	<span class="subheading mb-3">Testimonials</span>
            <h2>Testimonials</h2>
          </div>
        </div>
        <div class="row ftco-animate">
            <div class="col-md-12">
                <div class="carousel-stories owl-carousel">
                    <div 
                        class="item"
                        v-for="item in contents"
                        :key="item.id"
                    >
                        <div class="row justify-content-center">
                            <div class="col-md-11">
                                <div class="stories-wrap d-md-flex">
                                    <div 
                                        v-if="item.image"
                                        class="img" 
                                        :style="'background-image: url('+ item.image.url +');'"
                                    ></div>
                                    <div class="text p-4 py-xl-5 px-xl-5 d-flex align-items-center">
                                        <div class="desc w-100">
                                            <span v-html="item.content"></span>
                                            <div class="pt-4">
                                                <p class="name mb-0">
                                                    {{ item.title }}
                                                </p>
                                                <span class="position">
                                                    {{ item.description }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
</template>

<script>
    export default {
        name: 'Stories',
        props: ['stories'],
        computed: {
            category() {
                return this.stories && this.stories.category ? this.stories.category : false;
            },
            contents() {
                return this.stories && this.stories.contents ? this.stories.contents : false;
            }
        },
        methods: {
            loadScripts() {
                var carousel = function() {
                    $('.home-slider').owlCarousel({
                    loop:true,
                    autoplay: true,
                    margin:0,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    nav:true,
                    dots: true,
                    autoplayHoverPause: false,
                    items: 1,
                    navText : ["<span class='ion-ios-arrow-back'></span>","<span class='ion-ios-arrow-forward'></span>"],
                    responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1000:{
                        items:1
                    }
                    }
                    });

                    $('.carousel-testimony').owlCarousel({
                        center: true,
                        loop: true,
                        items:1,
                        margin: 30,
                        stagePadding: 0,
                        nav: false,
                        navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
                        responsive:{
                            0:{
                                items: 1
                            },
                            600:{
                                items: 2
                            },
                            1000:{
                                items: 3
                            }
                        }
                    });

                    $('.carousel-stories').owlCarousel({
                    loop:true,
                    autoplay: true,
                    autoHeight: false,
                    margin:30,
                    nav:true,
                    dots: false,
                    autoplayHoverPause: false,
                    items: 1,
                    navText : ["<p><span class='fa fa-chevron-left'></span></p>","<p><span class='fa fa-chevron-right'></span></p>"],
                    responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1000:{
                        items:1
                    }
                    }
                    });

                };
                carousel();
            }
        },
        mounted() {
            this.loadScripts();
        }
    }
</script>
 
<style lang="scss" scoped>

</style>