<template>
    <section class="ftco-section" v-if="contents && contents.length > 0">
        <div class="container">
            <div class="row justify-content-center pb-5 mb-3">
            <div class="col-md-7 heading-section text-center ftco-animate">
                <h2>{{ category.description }}</h2>
                <span class="subheading">{{ category.title }}</span>
            </div>
            </div>
            <div class="row d-flex">
                <div 
                    class="col-md-4 d-flex ftco-animate"
                    v-for="item in contents"
                    :key="item.id"
                >
                    <div class="blog-entry align-self-stretch">
                        <router-link
                            v-if="item.image"
                            :to="'/menu/' + category.route + '/' + item.slug"
                            class="block-20 rounded" 
                            :style="'background-image: url('+ item.image.url +');'"
                        >
                        </router-link>
                        <div class="text mt-3">
                            <div class="meta mb-2">
                            <div>
                                <router-link 
                                :to="'/menu/' + category.route + '/' + item.slug"
                                >
                                    {{ getDateFormat(item.created_at) }}
                                </router-link> 
                            </div>
                            <!-- <div><a href="#">Admin</a></div> -->
                            <!-- <div><a href="#" class="meta-chat"><span class="fa fa-comment"></span> 3</a></div> -->
                            </div>
                            <h3 class="heading">
                                <router-link 
                                    :to="'/menu/' + category.route + '/' + item.slug"
                                >
                                    {{ item.description }}
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment';
    
    export default {
        name: 'Blog',
        props: ['blog'],
        computed: {
            category() {
                return this.blog && this.blog.category ? this.blog.category : false;
            },
            contents() {
                return this.blog && this.blog.contents ? this.blog.contents : false;
            }
        },
        methods: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            }
        }
    }
</script>
 
<style lang="scss" scoped>

</style>