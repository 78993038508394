<template>
    <div>
        <footer class="footer" id="myFooter">
			<div class="container">
				<!-- <div class="row justify-content-center">
					<div class="col-10 col-lg-6">
						<div class="subscribe mb-5">
							<form action="#" class="subscribe-form">
                <div class="form-group d-flex">
                  <input type="text" class="form-control rounded-left" placeholder="Enter email address">
                  <input type="submit" value="Subscribe" class="form-control submit px-3">
                </div>
              </form>
						</div> 
					</div>
				</div> -->
				<div class="row">
					<div class="col-md-4 col-lg-5">
						<div class="row">
							<div class="col-md-12 col-lg-8 mb-md-0 mb-4">
								<img src="/logo.png" :alt="getsiteName" style="height: 60px;">
								<p>{{ params.description }}</p>
							</div>
						</div>
					</div>
					<div class="col-md-8 col-lg-7">
						<div class="row">
							<div class="col-md-6 mb-md-0 mb-4 border-left">
								<h2 class="footer-heading">Quick links</h2>
								<ul class="list-unstyled">
									<li><router-link to="/" class="py-1 d-block">I Want To Share</router-link></li>
									<li><router-link to="/blog" class="py-1 d-block">Blog</router-link></li>
									<li><router-link to="/contact" class="py-1 d-block">Contact Us</router-link></li>
									<li><router-link to="/privacy-policy" class="py-1 d-block">Privacy Policy</router-link></li>
								</ul>
							</div>
							<div class="col-md-6 mb-md-0 mb-4 border-left">
								<h2 class="footer-heading">Social</h2>
								<ul class="list-unstyled">
									<li><a :href="params.facebook" class="py-1 d-block">Facebook</a></li>
									<li><a :href="params.twitter" class="py-1 d-block">Twitter</a></li>
									<li><a :href="params.instagram" class="py-1 d-block">Instagram</a></li>
									<!-- <li><a href="#" class="py-1 d-block">Google</a></li> -->
									</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-5">
					<div class="col-md-6 col-lg-8">

						<!-- <p class="copyright">
							Copyright &copy;
							<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with 
							<i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a>
						</p> -->
					</div>
					<div class="col-md-6 col-lg-4 text-md-right">
						<p class="mb-0 list-unstyled">
							<!-- <router-link class="mr-md-3" to="/">Terms</router-link> -->
							<router-link class="mr-md-3" to="/privacy-policy">Privacy Policy</router-link>
							<!-- <a class="mr-md-3" href="#">Compliances</a> -->
						</p>
					</div>
				</div>
			</div>
		</footer>

        <!-- loader -->
        <div id="ftco-loader" class="show fullscreen">
            <svg class="circular" width="48px" height="48px">
                <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
            </svg>
        </div>
    </div>
</template>

<script>
	import { mapGetters } from 'vuex'

    export default {
        name: 'Footer',
        props: ['params', 'services'],
        computed: {
			...mapGetters(['getsiteName']),
            contents() {
                return this.services && this.services.contents ? this.services.contents : false;
            }
        },
		methods: {
			hideFooter() {
				var display = document.getElementById("myFooter").style.display;
				if(display=="none")
					document.getElementById("myFooter").style.display="block";
				else
					document.getElementById("myFooter").style.display="none";
			},
			autoHideFooter() {
				var isMobile = false
				if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
					|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
					isMobile = true;
				}
				if(isMobile) {
					var display = document.getElementById("myFooter").style.display;
					if(display=="none")
						document.getElementById("myFooter").style.display="block";
					else
						document.getElementById("myFooter").style.display="none";
				} else {
					document.getElementById("myFooter").style.display="block";
				}
			}
		},
		mounted() {
			this.autoHideFooter();

		}
    }
</script>

<style lang="scss" scoped>

</style>