import { createStore } from 'vuex';
import url from './modules/url/url';
import homepage from './modules/homepage/homepage'
import pages from './modules/pages/pages'
import user from './modules/user/user'


const store = createStore({
    modules: {
        url,
        homepage,
        pages,
        user
    }
})

export default store
