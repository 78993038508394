<template>
    <div>
        <div class="wrap">
			<div class="container">
				<div class="row justify-content-between">
						<div class="col d-flex align-items-center" v-if="params">
							<p class="mb-0 phone">
								<!-- <span class="mailus">Phone no: </span> 
								<a :href="'tel:'+params.phone">{{ params.phone }}</a> &nbsp;&nbsp;&nbsp; -->
								<span class="mailus">Email us: </span> 
								<a :href="'mailto:'+params.email">{{ params.email }}</a>
							</p>
						</div>
						<div class="col d-flex justify-content-end">
							<div class="social-media">
				    		<p class="mb-0 d-flex">
				    			<a :href="params.facebook" class="d-flex align-items-center justify-content-center">
									<span class="fa fa-facebook"><i class="sr-only">Facebook</i></span>
								</a>
				    			<a :href="params.twitter" class="d-flex align-items-center justify-content-center">
									<span class="fa fa-twitter"><i class="sr-only">Twitter</i></span>
								</a>
				    			<a :href="params.instagram" class="d-flex align-items-center justify-content-center">
									<span class="fa fa-instagram"><i class="sr-only">Instagram</i></span>
								</a>
				    		</p>
			        </div>
						</div>
				</div>
			</div>
		</div>
		<nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
			<div class="container">
				<router-link class="navbar-brand" to="/">
					<img src="/logo.png" :alt="getsiteName" style="height: 100px;">
				</router-link>
			<button 
				class="navbar-toggler" 
				type="button" data-toggle="collapse" 
				data-target="#ftco-nav" 
				aria-controls="ftco-nav" 
				aria-expanded="false" 
				aria-label="Toggle navigation"
			>
				<span class="fa fa-bars"></span> Menu
			</button>
			<div class="collapse navbar-collapse" id="ftco-nav">
				<ul class="navbar-nav ml-auto">
					<li :class="'nav-item ' + ((currentRoute == '/') ? 'active' : '')">
						<router-link to="/" class="nav-link">Home</router-link>
					</li>

					<li :class="'nav-item ' + ((currentRoute == '/dashboard') ? 'active' : '')" v-if="isLoggedIn">
						<router-link to="/dashboard" class="nav-link">Dashboard</router-link>
					</li>

					<li :class="'nav-item ' + ((currentRoute == '/login') ? 'active' : '')" v-if="! isLoggedIn">
						<router-link to="/login" class="nav-link">Login</router-link>
					</li>

					<li :class="'nav-item ' + ((currentRoute == '/share') ? 'active' : '')">
						<router-link to="/share" class="nav-link">I Want to Share</router-link>
					</li>
					<li :class="'nav-item ' + ((currentRoute == '/items') ? 'active' : '')">
						<router-link to="/items" class="nav-link">All Items</router-link>
					</li>
					<li 
						:class="'nav-item ' + ((currentRoute == '/'+ menu.route) ? 'active' : '')"
						v-for="menu in menus"
						:key="menu.id"
					>
						<router-link :to="'/'+menu.route" class="nav-link">{{ menu.title }}</router-link>
					</li>
					<li :class="'nav-item ' + ((currentRoute == '/contact') ? 'active' : '')">
						<router-link to="/contact" class="nav-link">Contact Us</router-link>
					</li>					

					<li :class="'nav-item ' + ((currentRoute == '/logout') ? 'active' : '')" v-if="isLoggedIn">
						<router-link to="/logout" class="nav-link">Logout</router-link>
					</li>

				</ul>
			</div>
			</div>
		</nav>
        <!-- END nav -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'Header',
        props: ['menus', 'params', 'isLoadingBlade'],
        computed: {
            ...mapGetters(['getsiteName', 'isLoggedIn']),
			currentRoute() {
				return this.$route.path;
			}
        }
    }
</script>

<style lang="scss" scoped>

</style>