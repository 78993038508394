<template>
    <div>
        
		<!-- header template is included here -->
		<Header 
            :menus="menus"
            :params="params"
            :isLoadingBlade="isLoadingBlade"
            v-if="params"
        />

        <section class="ftco-section">
            <div class="container">
                
                <ul class="nav nav-tabs nav-fill">
                    <li class="nav-item">
                        <a class="nav-link active text-uppercase" href="#openItems" data-toggle="tab">Items To Be Shared</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-uppercase" href="#sharedItemsEngaged" data-toggle="tab">Shared Items Engaged</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link  text-uppercase" data-toggle="tab" href="#inTransitItems">Items In Transit</a>
                    </li> 
                    <li class="nav-item">
                        <a class="nav-link  text-uppercase" data-toggle="tab" href="#sharedItems">Shared Items</a>
                    </li> 
                    <li class="nav-item">
                        <a class="nav-link  text-uppercase" data-toggle="tab" href="#receivedItemsEngaged">Items To Be Received</a>
                    </li>                 
                    <li class="nav-item">
                        <a class="nav-link text-uppercase" href="#receivedItems" data-toggle="tab">Received Items</a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div id="openItems" class="tab-pane active">
                        <!-- User received items template included here -->
                        <UserSharedItemsPage 
                            :option="'open'"
                            :title="'Items to be shared'"
                        />
                    </div>

                    <div id="sharedItemsEngaged" class="tab-pane">
                        <!-- User received items template included here -->
                        <UserSharedItemsPage 
                            :option="'shared-engaged'"
                            :title="'Shared items engaged'"
                        />
                    </div>

                    <div id="inTransitItems" class="tab-pane fade">
                        <!-- User shared items template included here -->
                        <UserSharedItemsPage 
                            :option="'in-transit'"
                            :title="'Items in transit'"
                        />
                    </div>

                    <div id="sharedItems" class="tab-pane fade">
                        <!-- User shared items template included here -->
                        <UserSharedItemsPage 
                            :option="'shared'"
                            :title="'Shared items'"
                        />
                    </div>

                    <div id="receivedItemsEngaged" class="tab-pane fade">
                        <!-- User shared items template included here -->
                        <UserSharedItemsPage 
                            :option="'received-engaged'"
                            :title="'Items to be received'"
                        />
                    </div>          
                    
                    <div id="receivedItems" class="tab-pane fade">
                        <!-- User received items template included here -->
                        <UserSharedItemsPage 
                            :option="'received'"
                            :title="'Received items'"
                        />
                    </div>
                </div>
            </div>
        </section>
        

        <div id="ftco-loader" class="show fullscreen" v-if="isLoadingBlade">
            <svg class="circular" width="48px" height="48px">
                <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
            </svg>
        </div>

		<!-- Footer template is included here -->
		<Footer 
            :params="params"
        />
		
    </div>
</template>

<script>
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import UserSharedItemsPage from '../layouts/dashboard/UserSharedItemsPage.vue'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'DashboardPage',
        components: {
            Header,
            Footer,
            UserSharedItemsPage
        },
        data() {
            return {
                isLoadingBlade: false,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData']),
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                setTimeout(() => { 
                    this.isLoadingBlade = false; 
                    this.removeLoader();
                }, 1000);
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            }
        },
        created() {
            this.loadHomepage();
        },
        mounted() {
            setTimeout(() => { 
                    const scripts = [
                    "/js/main.js"
                ];
                scripts.forEach(script => {
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag); 
                    }
                });

                // this.loadScripts();
            }, 2000)
        }
    }
</script>

<style lang="scss" scoped>

</style>