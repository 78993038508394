<template>
    <div>

        <!-- this is the header -->
        <Header 
            :menus="menus"
            :params="params"
            :isLoadingBlade="isLoadingBlade"
        />
        <!-- end:header-top -->

        <PageTemplate
            :record="content.menu.page"
            :category="content.category"
            :params="params"
            v-if="content.records && content.menu && (content.type == 'page')"
        />

        <GridTemplate
            :category="content.category"
            :menu="content.menu"
            :contents="content.records"
            :paginate="paginate"
            v-if="content && content.menu && content.records && (content.type == 'contents')"
        />
        
        <MixedTemplate
            :content="content"
            :paginate="paginate"
            v-if="content && content.menu && content.records && (content.type == 'mixed')"
        />

        <!-- Footer included here -->
        <Footer 
            :params="params"
            :services="services"
        />

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import PageTemplate from '../layouts/PageTemplate.vue'
    import GridTemplate from '../layouts/GridTemplate.vue'
    import MixedTemplate from '../layouts/MixedTemplate.vue'

    export default {
        name: 'SubMenuRoutePage',
        components: {
            Header,
            Footer,
            PageTemplate,
            GridTemplate,
            MixedTemplate
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: [],
                content: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'paginateNo']),
            getSearchUrl() {
                return this.$route
            }
        },
        methods: {
            ...mapActions(['getSubMenuPageContent', 'getPaginationRecords']),
            paginate(url) {
                if(url) {
                    this.errorPage = false;
                    this.isLoadingBlade = true;
                    this.getPaginationRecords(url).then((res) => {// get paginated records
                        if(res.status == 200) { // set data
                            this.setData(res.data);
                        } else {
                            this.isLoadingBlade = false;
                            this.errorPage = true
                            this.errorMsg = 'Page was not able to load, reload page...'
                        }
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    });
                }
            },
            loadPage() {
                this.errorPage = false;
                let data = { 
                    menu : this.$route.params.menu,
                    slug : this.$route.params.slug,
                    limit: this.paginateNo
                };
                this.getSubMenuPageContent(data).then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services; // set service contents
                this.content = data.content;// get menu content
                setTimeout(() => { this.isLoadingBlade = false; }, 1000)
            }
        },
        created() {
            this.loadPage(); // get page contents
        }
    }
</script>

<style lang="scss" scoped>

</style>