<template>
    <div>
        
		<!-- header template is included here -->
		<Header 
            :menus="menus"
            :params="params"
            :isLoadingBlade="isLoadingBlade"
            v-if="params"
        />

        <section class="ftco-section">

            <!-- items display page included here -->
            <ItemsDisplayPage 
                :records="items"
                :pagination="pagination"
                :paginate="paginate"
                :isLoading="isLoading"
            />
            
            <div class="container" v-if="items && items.length == 0">
                <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="container text-center">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                                <h1 class="display-1">Empty</h1>
                                <h1 class="mb-4 text-capitalize">No Shared Items Yet!!!</h1>
                                <p class="mb-4">Kindly go share items and be an helping hand plug...</p>
                                <router-link
                                    to="/share"
                                    class="btn btn-primary py-3 px-5"
                                >
                                    I Want to Share
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div id="ftco-loader" class="show fullscreen" v-if="isLoadingBlade">
            <svg class="circular" width="48px" height="48px">
                <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
            </svg>
        </div>

		<!-- Footer template is included here -->
		<Footer 
            :params="params"
        />
		
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import ItemsDisplayPage from '../layouts/dashboard/ItemsDisplayPage.vue'

    export default {
        name: 'SharedItemsPage',
        components: {
            Header,
            Footer,
            ItemsDisplayPage
        },
        data() {
            return {
                isLoading: false,
                isLoadingBlade: false,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                records: [],
                items: []
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'paginateNo']),
            pagination() {
                let data = this.records;
                return {
                    // hasPagination: (data.prev_page_url || data.next_page_url),
                    hasPagination: data.next_page_url,
                    links: data.links
                }
            }
        },
        methods: {
            ...mapActions(['getSharedItems', 'getPaginationRecords']),
            paginate(url) {
                this.errorPage = false;
                this.isLoadingBlade = false;
                this.isLoading = true;
                this.getPaginationRecords(url).then((res) => {// get paginated records
                    if(res.status == 200) { // set data
                        this.setData(res.data, true);
                    } else {
                        this.isLoading = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoading = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            loadSharedItems() {// get shared items
                this.errorPage = false;
                this.isLoadingBlade = true;
                let data = { limit: this.paginateNo };
                this.getSharedItems(data).then((res) => {// get shared items
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data, status = false) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters 
                this.records = data.items;// set items old function  
                if(status) {// add more items 
                    let currentItems = this.items; 
                    let newItems = data.items.data;
                    this.items = currentItems.concat(newItems);
                } else {
                    this.items = data.items.data;
                }
                setTimeout(() => { 
                    this.isLoadingBlade = false; 
                    this.isLoading = false;
                    this.removeLoader();
                }, 1000);
            },
            removeLoader() {
                var loader = function() { 
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
            loadScripts() {
                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {                            
                            i++;
                            $(this.element).addClass('item-animate');
                            setTimeout(function(){
                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });                                
                            }, 100);                            
                        }
                    } , { offset: '95%' } );
                };
                contentWayPoint();
            }
        },
        created() {
            this.loadSharedItems();
        },
        mounted() {
            setTimeout(() => { 
                    const scripts = [
                    "/js/main.js"
                ];
                scripts.forEach(script => {
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag); 
                    }
                });
                this.loadScripts();
            }, 1000)
        }
    }
</script>

<style lang="scss" scoped>

</style>