<template>

    <div>
        <div 
            v-if="isLoading" 
            class="row mt-5 text-center" 
        >
            <img src="/images/load-more.gif" alt="Load More..." style="margin: auto; height: 100px;" />
        </div>

        <div 
            class="row mt-5 text-center" 
            
            v-if="pagination.hasPagination && ! isLoading"
        >
            <a
                href="javascript:void(0)"
                class="btn btn-primary btn-outline-info"
                style="margin: auto;"
                @click="paginate(pagination.hasPagination)"
            >
                Load More...
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadMorePage',
        props: ['paginate', 'pagination', 'isLoading']
    }
</script>

<style lang="scss" scoped>
    .disabled {
        pointer-events: none;
        cursor: default;
    }
</style>