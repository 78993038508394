<template>
    <div>
        <div v-if="! errorPage">
            <!-- header template is included here -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
                v-if="params"
            />

            <section class="hero-wrap hero-wrap-2" style="background-image: url('images/bg_2.jpg');" data-stellar-background-ratio="0.5">
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text align-items-end">
                    <div class="col-md-9 ftco-animate pb-5">
                        <p class="breadcrumbs mb-2">
                            <span class="mr-2">
                                <router-link to="/">Home <i class="ion-ios-arrow-forward"></i></router-link>
                            </span> 
                            <span>Contact us <i class="ion-ios-arrow-forward"></i></span>
                        </p>
                        <h1 class="mb-0 bread">Contact us</h1>
                    </div>
                    </div>
                </div>
            </section>
                    
                
            <section class="ftco-section bg-light">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div class="contact-wrap w-100 p-md-5 p-4">
                                            <h3 class="mb-4">Get in touch</h3>
                                            <!-- <div id="form-message-warning" class="mb-4"></div> 
                                            <div id="form-message-success" class="mb-4">
                                                Your message was sent, thank you!
                                            </div> -->
                                            <form @submit.prevent="submitContact" name="contactForm" class="contactForm">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="label" for="name">Full Name</label>
                                                            <input 
                                                                type="text" 
                                                                class="form-control" 
                                                                name="name" 
                                                                id="name" 
                                                                placeholder="Name"
                                                                v-model="record.name"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6"> 
                                                        <div class="form-group">
                                                            <label class="label" for="email">Email Address</label>
                                                            <input 
                                                                type="email" 
                                                                class="form-control" 
                                                                name="email" 
                                                                id="email" 
                                                                placeholder="Email"
                                                                v-model="record.email"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="label" for="subject">Phone Number</label>
                                                            <input 
                                                                type="phone" 
                                                                class="form-control" 
                                                                name="phone" 
                                                                id="phone" 
                                                                placeholder="Phone Number"
                                                                v-model="record.phone"
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="label" for="#">Message</label>
                                                            <textarea 
                                                                name="message" 
                                                                class="form-control" 
                                                                id="message" 
                                                                cols="30" 
                                                                rows="4" 
                                                                placeholder="Message"
                                                                v-model="record.message"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <input 
                                                                type="submit" 
                                                                :value="(this.isloading ? 'Please Wait...' : 'Send Message')" 
                                                                class="btn btn-primary"
                                                            >
                                                            <!-- <div class="submitting"></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-5 d-flex align-items-stretch" v-if="params">
                                        <div class="info-wrap bg-primary w-100 p-md-5 p-4">
                                            <h3>Let's get in touch</h3>
                                            <p class="mb-4">We're open for any suggestion or just to have a chat</p>
                                            <div class="dbox w-100 d-flex align-items-start">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <span class="fa fa-map-marker"></span>
                                                </div>
                                                <div class="text pl-3">
                                                    <p><span>Address:</span> {{ params.address }}</p>
                                                </div>
                                            </div>
                                            <div class="dbox w-100 d-flex align-items-center">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <span class="fa fa-phone"></span>
                                                </div>
                                                <div class="text pl-3">
                                                    <p><span>Phone:</span> <a :href="'tel:'+params.phone">{{ params.phone }}</a></p>
                                                </div>
                                            </div>
                                            <div class="dbox w-100 d-flex align-items-center">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <span class="fa fa-paper-plane"></span>
                                                </div>
                                                <div class="text pl-3">
                                                    <p><span>Email:</span> <a :href="'mailto:'+params.email">{{ params.email }}</a></p>
                                                </div>
                                            </div>
                                            <div class="dbox w-100 d-flex align-items-center">
                                                <div class="icon d-flex align-items-center justify-content-center">
                                                    <span class="fa fa-whatsapp"></span>
                                                </div>
                                                <div class="text pl-3">
                                                    <p><span>WhatsApp: </span> 
                                                        <a 
                                                            :href="'https://api.whatsapp.com/send?phone='+ params.whatsapp"
                                                            target="_blank"
                                                        >
                                                            {{ params.whatsapp }}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div id="map" class="map"></div>
                        </div> -->
                    </div>
                </div>
            </section>

            <!-- Footer template is included here -->
            <Footer 
                :params="params"
            />
            
        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>


<script>

    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue' 
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";
    import ErrorPage from './ErrorPage.vue';

    export default {    
        name: 'ContactPage',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        components: {
            Header,
            Footer,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: [],
                record: {
                    name: null,
                    email: null,
                    message: null,
                    phone: null,
                    token: null
                },
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData', 'updateContact']),
            submitContact() {
                let check = this.validateInputs();
                if(check) {
                    this.isloading = true;
                    this.$recaptcha("login").then(token => {
                    this.record.token = token;
                        this.updateContact(this.record).then((res) => {// get homepage data
                            this.isloading = false;
                            if(res.status == 200  && res.data.status) { // set data
                                this.emptyRecord();
                                // $('.contact__msg').css('display', 'block');
                                // setTimeout(() => { $('.contact__msg').css('display', 'none'); }, 5000)
                                swal.fire('Feedback Sent', res.data.msg, 'success');
                            } else {
                                this.isLoadingBlade = false;
                                swal.fire('Error Occurred', res.data.msg, 'error');
                            }
                        }).
                        catch(() => {
                            this.isLoadingBlade = false;
                            this.isloading = false;
                            let msg = 'Error occurred while sending message, kindly reload page and try again...';
                            swal.fire('Error Occurred', msg, 'error');
                        });
                    }).catch(() => {
                        this.isloading = false;
                        let msg = 'Error occurred while sending message, kindly reload page and try again...';
                        swal.fire('Error Occurred', msg, 'error');
                    });
                }
            },
            validateInputs() {
                let status = this.record.name && this.record.email && this.record.message;
                if(status) {
                    return true
                } else {
                    let msg = 'Empty Field(s), fill the necessary fields and try again...';
                    swal.fire('Error Occurred', msg, 'error');
                    return false;
                }
            },
            emptyRecord() {
                return this.record = {
                    name: null,
                    email: null,
                    message: null,
                    phone: null,
                    token: null
                };
            },
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services;// get services
                setTimeout(() => { 
                    this.isLoadingBlade = false;
                    this.removeLoader()
                }, 1000)
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
            loadScripts() {
                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {                            
                            i++;
                            $(this.element).addClass('item-animate');
                            setTimeout(function(){
                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });
                                
                            }, 100);                            
                        }
                    } , { offset: '95%' } );
                };
                contentWayPoint(); 
            }
        },
        created() {
            this.loadHomepage(); // get page
        },
        mounted() {
            this.loadScripts();    
        }
    }
</script>

<style lang="scss" scoped>

</style>