<template>
    <div>
        <div v-if="! errorPage">
            <!-- this is the header -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
                v-if="params"
            />
            <!-- end:header-top -->
            

            <PageTemplate
                :record="page.record"
                :category="page.category"
                :stories="stories"
                :params="params"
                v-if="page && page.record && ! isLoadingBlade"
            />

            <div id="ftco-loader" class="show fullscreen" v-if="isLoadingBlade">
                <svg class="circular" width="48px" height="48px">
                    <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                    <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
                </svg>
            </div>


            <!-- Footer template is included here -->
            <Footer 
                :params="params"
            />
        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import PageTemplate from '../layouts/PageTemplate.vue'
    import ErrorPage from './ErrorPage.vue';

    export default {
        name: 'MenuPage',
        components: {
            Header,
            Footer,
            PageTemplate,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                stories: [],
                page: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData', 'getMenuPage']),
            loadPage() {
                this.errorPage = false;
                let data = { 
                    category : this.$route.params.category,
                    slug : this.$route.params.slug,
                }
                this.getMenuPage(data).then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.stories = data.stories; // set blog contents
                this.page = data.page;// get page content
                setTimeout(() => { 
                    this.isLoadingBlade = false; 
                    this.removeLoader();
                }, 1000);
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
            loadScripts() {
                setTimeout(() => { 
                        const scripts = [
                        "/js/main.js"
                    ];
                    scripts.forEach(script => {
                        let tag = document.head.querySelector(`[src="${ script }"`);
                        if (!tag) {
                            tag = document.createElement("script");
                            tag.setAttribute("src", script);
                            tag.setAttribute("type", 'text/javascript');
                            document.head.appendChild(tag); 
                        }
                    });
                }, 2000);
            }
        },
        created() {
            this.loadPage(); // get page contents
        },
        mounted() {
            this.loadScripts();
        }
    }
</script>

<style lang="scss" scoped>

</style>