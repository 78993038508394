<template>
    <div v-if="record && category">
        <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_2.jpg');" data-stellar-background-ratio="0.5">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text align-items-end">
                <div class="col-md-9 ftco-animate pb-5">
                    <p class="breadcrumbs mb-2">
                        <span class="mr-2">
                            <router-link to="/">Home <i class="ion-ios-arrow-forward"></i></router-link>
                        </span> 
                        <span>{{ record.title }} <i class="ion-ios-arrow-forward"></i></span>
                    </p>
                    <h1 class="mb-0 bread">{{ record.title }}</h1>
                </div>
                </div>
            </div>
        </section>

        <div class="alert alert-block alert-danger d-flex align-items-center" role="alert" v-if="params && isAboutUsPage">             
            <div>
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                &nbsp;&nbsp;
                {{ params.reported_text }}
            </div>
        </div>            
        
        <section class="ftco-section ftco-no-pt ftco-no-pb bg-light">
            <div class="container">
                <div class="row no-gutters">
                    <div 
                        v-if="record && record.image"
                        class="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" 
                        :style="'background-image: url('+ record.image.url +');'"
                    >
                    </div>
                    <div class="col-md-7 wrap-about py-4 py-md-5 ftco-animate">
                        <div class="heading-section mb-5">
                            <div class="pl-md-5">
                                <span class="subheading mb-2" v-if="params && params.name">Welcome to {{ params.name  }}</span>
                                <h2 class="mb-2">{{ record.description  }}</h2>
                            </div>
                        </div>
                        <div class="pl-md-5">
                            <span v-html="record.content"></span>
                            <div class="founder d-flex align-items-center mt-5">
                                <!-- <div class="img" style="background-image: url(images/coach-1.jpg);"></div>
                                <div class="text pl-3">
                                    <h3 class="mb-0">Cythia Hunter</h3>
                                    <span class="position">Personal Dietitian</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- Stories template is included here -->
        <Stories 
            :stories="stories"
        />

        <ItemsCount 
            :itemsCount="itemsCount"
            v-if="itemsCount"
        />

    </div>
</template>

<script>
    import Stories from '../layouts/homepage/Stories.vue'
    import ItemsCount from '../layouts/ItemsCount.vue'

    export default {
        name: 'PageTemplate',
        props: ['record', 'category', 'params', 'stories', 'itemsCount'],
        components:  {
            Stories,
            ItemsCount
        },
        computed: {
            isAboutUsPage() {// check if it is about us page
                return (this.$route.path == "/about-us");
            }
        },
        methods: {
            loadScripts() {
                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {                            
                            i++;
                            $(this.element).addClass('item-animate');
                            setTimeout(function(){
                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });                                
                            }, 100);                            
                        }
                    } , { offset: '95%' } );
                };
                contentWayPoint();
            }
        },
        mounted() {
            this.loadScripts();
        }
    }
</script>

<style lang="scss" scoped>
    div {
        margin:0 auto;
    }
</style>