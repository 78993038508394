import axios from 'axios';

const state = {
    accessToken: localStorage.getItem('access_token') || null,
    email: localStorage.getItem('email'),
};

const getters = {
    isLoggedIn: state => state.accessToken !== null ? true : false,
    userEmail: state => state.email 
};

const actions = {
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 2;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('email', data.email);// set student id
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    },
    destroyAccessToken(state) {// destroy local storage
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('email');// remove student id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    async userLogin(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.userLoginUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async createAccount(state, data) {// signup
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createAccountUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async removeItemImage(state, data) {// signup
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.removeItemImageUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async publishUserItem(state, data) {// signup
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.publishUserItemUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async engageSharedItem(state, data) {// signup
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.engageSharedItemUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async userResetPassword(state, email) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.userResetPasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, { email : email }, { headers: header });// make api call
        return response.data;
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}