<template>
    <section class="ftco-section ftco-counter" id="section-counter" v-if="itemsCount">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div class="block-18 text-center">
                        <div class="text">
                            <strong 
                                class="number" 
                                :data-number="itemsCount.items"
                            >0</strong>
                        </div>
                        <div class="text">
                            <span>Items</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div class="block-18 text-center">
                    <div class="text">
                        <strong 
                            class="number" 
                            :data-number="itemsCount.to_be_shared"
                        >0</strong>
                    </div>
                    <div class="text">
                        <span>Items to be share</span>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div class="block-18 text-center">
                    <div class="text">
                        <strong 
                            class="number" 
                            :data-number="itemsCount.shared_items"
                        >0</strong>
                    </div>
                    <div class="text">
                        <span>Items shared</span>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div class="block-18 text-center">
                    <div class="text">
                        <strong 
                            class="number" 
                            :data-number="itemsCount.helped"
                        >0</strong>
                    </div>
                    <div class="text">
                        <span>Total Helped</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ItemsCount',
        props: ['itemsCount'],
        methods: {
            loadScripts() {
                var counter = function() {		
                    $('#section-counter').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {
                            var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',')
                            $('.number').each(function(){
                                var $this = $(this),
                                    num = $this.data('number');
                                $this.animateNumber(
                                {
                                    number: num,
                                    numberStep: comma_separator_number_step
                                }, 7000
                                );
                            });
                            
                        }
                    } , { offset: '95%' } );
                }
                counter();
            }
        },
        mounted() {
            this.loadScripts();
        }
    }
</script>

<style lang="scss" scoped>

</style>