const rootUrl = 'https://test.admin.welovetoshare.org/api';
const siteName = 'We Love To Share';
// homepage Urls 
const getHomepageDataUrl = rootUrl+'/get-homepage-data';
const getOtherContentPageUrl = rootUrl+'/get-other-content';
const paymentTokenUrl = rootUrl+'/get-payment-token';
const updatePaymentTransactionUrl = rootUrl+'/update-payment-transaction';
// pages urls
const getMenuPageUrl = rootUrl+'/get-menu-page';
const getOtherContentPagesUrl =  rootUrl+'/get-other-content-pages';
const getMainMenuContentUrl = rootUrl+'/get-main-menu-content';
const getMainMenuContentsUrl = rootUrl+'/get-main-menu-contents';
const getOtherContentSinglePageUrl = rootUrl+'/get-other-content-page';
const updateContactUrl = rootUrl+'/update-contact';
const getSubMenuPageContentUrl = rootUrl+'/get-sub-menu-page-contents';
const getMenuRoutePageContentUrl = rootUrl+'/get-menu-page-contents';
const sendQuoteUrl = rootUrl+'/send-quote';
const getSharedItemsUrl = rootUrl+'/get/shared/items';
const getSharedItemDetailsUrl = rootUrl+'/get/shared/item/info';
const getUserSharedItemsUrl = rootUrl+'/get/user/shared/items';
// user urls
const createAccountUrl = rootUrl+'/user/register';
const userLoginUrl = rootUrl+'/user/login';
const engageSharedItemUrl = rootUrl+'/user/engage/item';
const userResetPasswordUrl = rootUrl+'/user/reset/password';
// upload item url
const uploadItemImageUrl = rootUrl+'/upload/item/image';
const removeItemImageUrl = rootUrl+'/remove/item/image';
const publishUserItemUrl = rootUrl+'/publish/user/item';


// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcY653geddeSRRmYOwvjce1';
const paginateNo = 9;
const recaptchaKey = '6LeRDMwpAAAAAI50mhU4-pbDlLv-NgK3DmJxItuK';

const state = {
    userResetPasswordUrl: userResetPasswordUrl,
    engageSharedItemUrl: engageSharedItemUrl,
    getUserSharedItemsUrl: getUserSharedItemsUrl,
    getSharedItemDetailsUrl: getSharedItemDetailsUrl,
    getSharedItemsUrl: getSharedItemsUrl,
    publishUserItemUrl: publishUserItemUrl,
    removeItemImageUrl: removeItemImageUrl,
    userLoginUrl: userLoginUrl,
    createAccountUrl: createAccountUrl,
    sendQuoteUrl: sendQuoteUrl,
    getMenuRoutePageContentUrl: getMenuRoutePageContentUrl,
    getSubMenuPageContentUrl: getSubMenuPageContentUrl,
    updatePaymentTransactionUrl: updatePaymentTransactionUrl,
    paymentTokenUrl: paymentTokenUrl,
    updateContactUrl: updateContactUrl,
    getOtherContentSinglePageUrl: getOtherContentSinglePageUrl,
    getMainMenuContentsUrl: getMainMenuContentsUrl,
    getMainMenuContentUrl: getMainMenuContentUrl,
    getOtherContentPagesUrl: getOtherContentPagesUrl,
    getMenuPageUrl: getMenuPageUrl,
    getOtherContentPageUrl: getOtherContentPageUrl,
    getHomepageDataUrl: getHomepageDataUrl,
    authorizationToken: authorizationToken
    
};

const getters = {
    uploadItemImageUrl: () => uploadItemImageUrl,
    getsiteName: () => siteName,
    paginateNo: () => paginateNo,
    recaptchaKey: () => recaptchaKey
};

const actions = {

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}