<template>
    <div 
        class="row mt-5"
        v-if="pagination.hasPagination"
    >
        <div class="col text-center">
            <div class="block-27">
                <ul>
                    <li 
                        v-for="(link, index) in pagination.links"
                        :key="index"
                        :class="(link.active ? 'active' : '')"
                    >
                        <a 
                            href="javascript:void(0)"
                            @click="paginate(link.url)"
                            :class="(link.url ? '' : 'disabled')"
                        >
                            <span v-html="link.label"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: ['paginate', 'pagination']
    }
</script>

<style lang="scss" scoped>
    .disabled {
        pointer-events: none;
        cursor: default;
    }
</style>