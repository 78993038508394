<template>
    <div>
        <!-- <link rel="stylesheet" href="/css/custom.css"> -->

        <div v-if="! errorPage && params">
            <!-- header template is included here -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
                v-if="params"
            />

            <!-- the login page is included here -->
            <LoginForm 
                :record="record"
                :loginUser="loginUser"
                :isLoading="isLoading"
                :changeFormPage="changeFormPage"
                v-if="! showFormPage && ! showForgotPassword"
            />

            <!-- the register page is included here -->
            <RegisterForm 
                :record="record"
                :registerUser="registerUser"
                :isLoading="isLoading"
                :changeFormPage="changeFormPage"
                v-if="showFormPage && ! showForgotPassword"
            />

            <!-- the forgot password page is included here -->
            <ForgotPassword 
                :record="record"
                :userForgotPassword="userForgotPassword"
                :isLoading="isLoading"
                :changeFormPage="changeFormPage"
                v-if="showForgotPassword"
            />

            <!-- Footer template is included here -->
            <Footer 
                :params="params"
            />

        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import ErrorPage from '../pages/ErrorPage.vue';
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";
    import Footer from '../layouts/Footer.vue'
    import LoginForm from './LoginForm.vue';
    import RegisterForm from './RegisterForm.vue'
    import ForgotPassword from './ForgotPassword.vue'

    export default {    
        name: 'LoginPage',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        components: {
            Header,
            Footer,
            ErrorPage,
            LoginForm,
            RegisterForm,
            ForgotPassword
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                record: {
                    email: null,
                    password: null,
                    password_confirmation: null,
                    token: null
                },
                isLoading: false,
                showFormPage: false,
                showForgotPassword: false
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'recaptchaKey'])
        },
        methods: {
            ...mapActions(['getHomepageData', 'userLogin', 'createAccount', 'storeAndLoginUser', 'destroyAccessToken', 'userResetPassword']),
            changeFormPage(status, fgtStatus) {
                this.emptyFields();
                this.showFormPage = status;
                return this.showForgotPassword = fgtStatus;              
            },
            emptyFields() {
                return this.record = {
                    email: null,
                    password: null,
                    password_confirmation: null,
                    token: null
                };
            },
            userForgotPassword() {
                let status = this.validEmail(this.record.email);
                if(status) {// login user
                    this.preloader();// show loader
                    this.isLoading = true;
                    this.userResetPassword(this.record.email).then((res) => {
                        this.isLoading = false;
                        if(res.status) {
                            swal.fire("Success", res.msg, "success");
                        } else {
                            this.destroyAccessToken();// destroy local token
                            swal.fire("Password Reset Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isLoading = false;
                        this.destroyAccessToken();// destroy local token                        
                        swal.fire("Server Failure!", 'kindly try again...', "warning");
                    });
                }
            },
            loginUser() {
                grecaptcha.execute(this.recaptchaKey , {action: 'submit'}).then((token) => {
                    return this.loginUserAccount(token);// create user account
                });
            },
            loginUserAccount(token) {
                let status = this.validateInputs(false);
                if(status) {// login user
                    this.preloader();// show loader
                    this.isLoading = true;
                    this.record.token = token;
                    this.userLogin(this.record).then((res) => {
                        this.isLoading = false;
                        if(res.status) {
                            this.signedIn("Signed in successfully");
                            this.storeAndLoginUser(res);
                            setTimeout(() => { window.location.href = '/share'; }, 3000)
                        } else {
                            this.destroyAccessToken();// destroy local token
                            swal.fire("Login Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isLoading = false;
                        this.destroyAccessToken();// destroy local token                        
                        swal.fire("Server Failure!", 'kindly try again...', "warning");
                    });
                }
            },
            registerUser() {// verify is human
                grecaptcha.execute(this.recaptchaKey , {action: 'submit'}).then((token) => {
                    return this.createUserAccount(token);// create user account
                });
            },
            createUserAccount(token) {
                let status = this.validateInputs(true);
                if(status) {// login user
                    this.preloader(); // show loader
                    this.isLoading = true;
                    this.record.token = token;
                    this.createAccount(this.record).then((res) => {
                        this.isLoading = false;
                        if(res.status) {
                            this.signedIn('Account was created successfully...');
                            this.storeAndLoginUser(res);
                            setTimeout(() => { this.$router.back(); }, 3000)
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isLoading = false;
                        swal.fire('Server Failure!', 'An error occurred while creating account, try again...', 'warning');
                    }); 
                }
                
            },
            validateInputs(status) {
                if(status) {
                    let check = this.validEmail(this.record.email) && this.record.password && this.record.password_confirmation 
                                        && (this.record.password == this.record.password_confirmation);
                    if(! check && (this.record.password != this.record.password_confirmation)) {
                        swal.fire('Password Mis-match', 'Password did not match...', 'warning');
                    } else if (! check && ! this.validEmail(this.record.email)) {
                        swal.fire('Invalid Email!', 'Kindly enter a valid email address and try again...', 'warning');
                    } else if (! check) {
                        swal.fire('Empty Field(s)!', 'Kindly fill the necessary fields and try again...', 'warning');
                    }
                    return check;
                } else {
                    let check = this.validEmail(this.record.email) && this.record.password;
                    if (! check && ! this.validEmail(this.record.email)) {
                        swal.fire('Invalid Email!', 'Kindly enter a valid email address and try again...', 'warning');
                    } else if(! check) {
                        swal.fire('Empty Field(s)!', 'Kindly fill the necessary fields and try again...', 'warning');
                    } 
                    return check;
                }
            },
            validEmail(email) {// eslint-disable-next-line
                var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEmail.test(email);
            },
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                setTimeout(() => { 
                    this.isLoadingBlade = false;
                    this.removeLoader()
                }, 1000)
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
            preloader() {
                return  swal.fire({
                    title: 'Loging in...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    // timerProgressBar: true,
                    willOpen: () => {
                        swal.showLoading()
                    },
                });
            },
            signedIn(text) {
                const Toast = swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = swal.stopTimer;
                        toast.onmouseleave = swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: text
                });
            }
        },
        created() {
            this.loadHomepage(); // get page
        }
    }
</script>

<style lang="scss" scoped>
   *,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.login-body{
    // background-color: #080710;
    background-color: #026C65;
    margin-bottom: -100px;
}
.background{
    width: 430px;
    height: 350px;
    position: relative;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 200px;
    width: 200px;
    position: relative;
    border-radius: 50%;
}
form{
    height: 520px;
    width: 400px;
    background-color: rgba(255,255,255,0.13);
    position: relative;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}
form *{
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
form h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

label{
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}
input{
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255,255,255,0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}
::placeholder{
    color: #e5e5e5;
}
button{
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}
.social{
  margin-top: 30px;
  display: flex;
}
.social div{
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255,255,255,0.27);
  color: #eaf0fb;
  text-align: center;
}
.social div:hover{
  background-color: rgba(255,255,255,0.47);
}
.social .fb{
  margin-left: 25px;
}
.social i{
  margin-right: 4px;
}
</style>