<template>
    <div>
        <div id="ftco-loader" class="show fullscreen" v-if="isLoadingBlade">
            <svg class="circular" width="48px" height="48px">
                <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
            </svg>
        </div>
		
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'LogoutPage',
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: ''
            }
        },
        methods: {
            ...mapActions(['destroyAccessToken']),
            logout() {
                this.errorPage = false;
                this.destroyAccessToken();
                setTimeout(() => { window.location.href='/share'; }, 2000)
            },
        },
        created() {
            this.logout();
        }
    }
</script>

<style lang="scss" scoped>

</style>