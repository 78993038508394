<template>
    <div>
        <div v-if="! errorPage && params">
            <!-- header template is included here -->
            <Header 
                :menus="menus"
                :params="params"
                :isLoadingBlade="isLoadingBlade"
                v-if="params"
            />

            <section class="hero-wrap hero-wrap-2" style="background-image: url('images/bg_2.jpg');" data-stellar-background-ratio="0.5">
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text align-items-end">
                    <div class="col-md-9 ftco-animate pb-5">
                        <p class="breadcrumbs mb-2">
                            <span class="mr-2">
                                <router-link to="/">Home <i class="ion-ios-arrow-forward"></i></router-link>
                            </span> 
                            <span>I Want to Share <i class="ion-ios-arrow-forward"></i></span>
                        </p>
                        <h1 class="mb-0 bread">I Want to Share</h1>
                    </div>
                    </div>
                </div>
            </section>

            <ShareForm 
                :params="params"
                :userEmail="userEmail"
                :uploadItemImageUrl="uploadItemImageUrl"
            />

            <ItemsCount 
                :params="params"
            />

            <!-- Footer included here -->
            <Footer 
                :params="params"
                :services="services"
                v-if="! isLoadingBlade"
            />
        </div>

        <ErrorPage 
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import ShareForm from '../layouts/homepage/ShareForm.vue'
    import ItemsCount from '../layouts/ItemsCount.vue'
    import ErrorPage from './ErrorPage.vue';

    export default {    
        name: 'SharePage',
        components: {
            Header,
            Footer,
            ShareForm,
            ErrorPage,
            ItemsCount
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: []
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'userEmail', 'uploadItemImageUrl'])
        },
        methods: {
            ...mapActions(['getHomepageData']),
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services;// get services
                setTimeout(() => { 
                    this.isLoadingBlade = false;
                    this.removeLoader()
                }, 1000)
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
            loadScripts() {
                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {                            
                            i++;
                            $(this.element).addClass('item-animate');
                            setTimeout(function(){
                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });
                                
                            }, 100);                            
                        }
                    } , { offset: '95%' } );
                };
                contentWayPoint(); 
            }
        },
        created() {
            this.loadHomepage(); // get page
        },
        mounted() {
            this.loadScripts();    
        }
    }
</script>

<style lang="scss" scoped>

</style>