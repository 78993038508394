<template>
    <div class="hero-wrap js-fullheight">
	    <div class="home-slider owl-carousel js-fullheight">
			<div 
				v-for="(slider, index) in sliders"
				:key="index"
				class="slider-item js-fullheight" 
				:style="'background-image:url('+slider.image.url+');'"
			>
				<div class="overlay"></div>
				<div class="container">
				<div 
					:class="'row no-gutters slider-text align-items-center '+(index == 2 ? 'justify-content-end' : '')"
				>
					<div class="col-md-6 ftco-animate">
						<div class="text w-100"> 
							<h2>{{ slider.title }}</h2>
							<h1 class="mb-4">{{ slider.description }}</h1>
							<p>
								<router-link to="/about-us" class="btn btn-primary">Learn more</router-link> 
								<router-link to="/contact" class="btn btn-white">Contact us</router-link>
							</p>
						</div>
					</div>
					</div>
				</div>
			</div>
	    </div>
    </div>
</template>

<script>
    export default {
        name: 'Slider',
        props: ['sliders'],
		methods: {
			loadSliderScripts() {
				var carousel = function() {
					$('.home-slider').owlCarousel({
					loop:true,
					autoplay: true,
					margin:0,
					animateOut: 'fadeOut',
					animateIn: 'fadeIn',
					nav:true,
					dots: true,
					autoplayHoverPause: false,
					items: 1,
					navText : ["<span class='ion-ios-arrow-back'></span>","<span class='ion-ios-arrow-forward'></span>"],
					responsive:{
					0:{
						items:1
					},
					600:{
						items:1
					},
					1000:{
						items:1
					}
					}
					});

					$('.carousel-testimony').owlCarousel({
						center: true,
						loop: true,
						items:1,
						margin: 30,
						stagePadding: 0,
						nav: false,
						navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
						responsive:{
							0:{
								items: 1
							},
							600:{
								items: 2
							},
							1000:{
								items: 3
							}
						}
					});

					$('.carousel-stories').owlCarousel({
					loop:true,
					autoplay: true,
					autoHeight: false,
					margin:30,
					nav:true,
					dots: false,
					autoplayHoverPause: false,
					items: 1,
					navText : ["<p><span class='fa fa-chevron-left'></span></p>","<p><span class='fa fa-chevron-right'></span></p>"],
					responsive:{
					0:{
						items:1
					},
					600:{
						items:1
					},
					1000:{
						items:1
					}
					}
					});

				};
				carousel();
			},
			loadTextScripts() {
				var contentWayPoint = function() { 
					var i = 0;
					$('.ftco-animate').waypoint( function( direction ) {
						if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {							
							i++;
							$(this.element).addClass('item-animate');
							setTimeout(function(){
								$('body .ftco-animate.item-animate').each(function(k){
									var el = $(this);
									setTimeout( function () {
										var effect = el.data('animate-effect');
										if ( effect === 'fadeIn') {
											el.addClass('fadeIn ftco-animated');
										} else if ( effect === 'fadeInLeft') {
											el.addClass('fadeInLeft ftco-animated');
										} else if ( effect === 'fadeInRight') {
											el.addClass('fadeInRight ftco-animated');
										} else {
											el.addClass('fadeInUp ftco-animated');
										}
										el.removeClass('item-animate');
									},  k * 50, 'easeInOutExpo' );
								});
								
							}, 100);							
						}
					} , { offset: '95%' } );
				};
				contentWayPoint(); 
			},
			loadResizeScript() {
				var fullHeight = function() {
					var isMobile = false; //initiate as false
					// device detection
					if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
						|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
						isMobile = true;
					}

					if(isMobile) {
						$('.js-fullheight').css('height', '400px'); 
						$(window).resize(function(){
							$('.js-fullheight').css('height', '400px'); 

						});
					} else {
						$('.js-fullheight').css('height', $(window).height());			
						$(window).resize(function(){
							$('.js-fullheight').css('height', $(window).height()); 
						});
					}
				};
				fullHeight();
			}
		},
		mounted() {
			this.loadSliderScripts();
			this.loadTextScripts();
			this.loadResizeScript();
		}
    }
</script>

<style lang="scss" scoped>

</style>