<template>
    <div v-if="menu && category">
        <!-- Page Header Start -->
        <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_2.jpg');" data-stellar-background-ratio="0.5">
            <div class="overlay"></div>
            <div class="container">
                <div class="row no-gutters slider-text align-items-end">
                    <div class="col-md-9 ftco-animate pb-5">
                        <p class="breadcrumbs mb-2">
                            <span class="mr-2">
                                <router-link to="/">Home <i class="ion-ios-arrow-forward"></i></router-link>
                            </span> 
                            <span class="text-white">{{ menu.title }} <i class="ion-ios-arrow-forward"></i></span>
                        </p>
                        <h1 class="mb-0 bread">{{ menu.title }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <!-- Page Header End -->
        
        <section class="ftco-section" v-if="records && records.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 ftco-animate">

                        <div 
                            class="story-wrap d-md-flex align-items-center"
                            v-for="item in records"
                            :key="item.id"
                        >
							<div 
                                v-if="item.image"
                                class="img" 
                                :style="'background-image: url('+ item.image.url +');'"
                            ></div>
							<div class="text pl-md-5">
								<h4><span>{{ item.title }}</span></h4>
                                <em>{{ getDateFormat(item.created_at) }}</em>
								<span v-html="item.content"></span>
								<p><router-link :to="'/menu/' + menu.route + '/' + item.slug" class="btn btn-primary">Read more</router-link></p>
							</div>
						</div>

                        <Pagination 
                            :pagination="pagination"
                            :paginate="paginate"
                        />
                    </div> <!-- .col-md-8 -->

                    <div class="col-lg-4 sidebar pl-lg-5 ftco-animate">
                        <div class="sidebar-box ftco-animate">
                            <div class="categories">
                                <h3>Ouick Links</h3>
                                <li><a href="#">Home <span class="ion-ios-arrow-forward"></span></a></li>
                                <li><a href="#">I Want To Share <span class="ion-ios-arrow-forward"></span></a></li>
                                <li><a href="#">Contact Us <span class="ion-ios-arrow-forward"></span></a></li>
                                <li><a href="#">Privacy Policy <span class="ion-ios-arrow-forward"></span></a></li>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section> <!-- .section -->

    </div>
</template>

<script>
    import moment from 'moment'
    import Pagination from '../layouts/Pagination.vue' 
    
    export default {
        name: 'GridTemplate',
        props: ['menu', 'category', 'contents', 'paginate'],
        components: {
            Pagination
        },
        computed: {
            formatDate(){
                return (date)=>{
                    return date ? moment(date).format('MMM Do') : 'N/A';
                }
            },
            records() {
                return this.contents.data;
            },
            pagination() {
                let data = this.contents;
                return {
                    hasPagination: (data.prev_page_url || data.next_page_url),
                    links: data.links
                }
            }
        },
        methods: {
            getDateFormat(value) {
                if (value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            },
            loadScripts() {
                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {                            
                            i++;
                            $(this.element).addClass('item-animate');
                            setTimeout(function(){
                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });                                
                            }, 100);                            
                        }
                    } , { offset: '95%' } );
                };
                contentWayPoint();
            }
        },
        mounted() {
            this.loadScripts()
        }
    }
</script>

<style lang="scss" scoped>

</style>