<template>
    <div class="login-body">
        <div class="background"></div>
        <form @submit.prevent="loginUser">
            <h3 class="text-uppercase">Sign In</h3>

            <label for="username">Email Address</label>
            <input type="text" placeholder="Email Address" id="username" v-model="record.email">

            <label for="password">Password</label>
            <input type="password" placeholder="Password" id="password" v-model="record.password">

            <button>{{ isLoading ? 'PLEASE WAIT...' : 'SIGN IN' }}</button>
            <br><br>
            <a 
                class="text-white sign-up" 
                href="javascript:void(0)"
                @click="changeFormPage(true, false)"
            >
                I don't have account? Sign Up
            </a>
            <br>
            <a 
                class="text-white sign-up" 
                href="javascript:void(0)"
                @click="changeFormPage(false, true)"
            >
                Forgot Password
            </a>
            <!-- <div class="social">
            <div class="go"><i class="fab fa-google"></i>  Sign Up</div>
            <div class="fb"><i class="fab fa-facebook"></i>  Forgot Password</div>
            </div> -->
        </form>
    </div>
</template>

<script>
    export default {    
        name: 'LoginForm',
        props: ['record', 'loginUser', 'isLoading', 'changeFormPage']
    }
</script>

<style lang="scss" scoped>
   *,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.login-body{
    // background-color: #080710;
    background-color: #026C65;
    margin-bottom: -100px;
}
.background{
    width: 430px;
    height: 350px;
    position: relative;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 200px;
    width: 200px;
    position: relative;
    border-radius: 50%;
}
form{
    height: 520px;
    width: 400px;
    background-color: rgba(255,255,255,0.13);
    position: relative;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}
form *{
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
form h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

label{
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}
input{
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255,255,255,0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}
::placeholder{
    color: #e5e5e5;
}
button{
    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}
.sign-up:hover {
    color: red;
}
.social{
  margin-top: 30px;
  display: flex;
}
.social div{
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255,255,255,0.27);
  color: #eaf0fb;
  text-align: center;
}
.social div:hover{
  background-color: rgba(255,255,255,0.47);
}
.social .fb{
  margin-left: 25px;
}
.social i{
  margin-right: 4px;
}
</style>