<template>
    <div>
        
		<!-- header template is included here -->
		<Header 
            :menus="menus"
            :params="params"
            :isLoadingBlade="isLoadingBlade"
            v-if="params"
        />

		<!-- slider template is included here -->
		<Slider 
            :sliders="sliders"
            v-if="sliders && sliders.length > 0"
        />
		
		<!-- Shared Items template is included here -->
		<SharedItems 
            :items="items"
            v-if="items && items.length > 0"
        />

        
        <div class="alert alert-block alert-danger d-flex align-items-center" role="alert" v-if="params">             
            <div>
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                &nbsp;&nbsp;
                {{ params.reported_text }}
            </div>
        </div>

		<!-- About template is included here -->
		<!-- <About 
            :record="aboutUs"
            v-if="aboutUs"
        /> -->

		<!-- Testimonials template is included here  reported_text --> 
		<!-- <Testimonials /> -->

		<!-- How it works template is included here -->
		<HowItWorks />

		<!-- Stories template is included here -->
		<!-- <Stories 
            :stories="stories"
        /> -->

		<!-- Blog template is included here -->
		<!-- <Blog 
            :blog="blog"
        /> -->

        <div id="ftco-loader" class="show fullscreen" v-if="isLoadingBlade">
            <svg class="circular" width="48px" height="48px">
                <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
            </svg>
        </div>

		<!-- Footer template is included here -->
		<Footer 
            :params="params"
        />
		
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Slider from '../layouts/sliders/Slider.vue'
    import About from '../layouts/homepage/About.vue'
    import SharedItems from '../layouts/homepage/SharedItems.vue'
    import Testimonials from '../layouts/homepage/Testimonials.vue'
    import Footer from '../layouts/Footer.vue'
	import HowItWorks from '../layouts/homepage/HowItWorks.vue'
	import Stories from '../layouts/homepage/Stories.vue'
	import Blog from '../layouts/homepage/Blog.vue'

    export default {
        name: 'Homepage',
        components: {
            Header,
            Slider,
            About,
            SharedItems,
            Testimonials,
            Footer,
			HowItWorks,
			Stories,
			Blog
        },
        data() {
            return {
                isLoadingBlade: false,
                errorPage: false,
                errorMsg: '',
                menus: [],
                sliders: [],
                items: [],
                stories: [],
                partners: [],
                blog: {},
                params: {},
                aboutUs: {}
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData']),
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.sliders = data.sliders;// set sliders
                this.items = data.items;// get items
                this.partners = data.partners;// get partners
                this.stories = data.stories; // get stories
                this.blog = data.blog; // get blog
                this.aboutUs = data.about_us;// get about us
                setTimeout(() => { 
                    this.isLoadingBlade = false; 
                    this.removeLoader();
                }, 1000);
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
        },
        created() {
            this.loadHomepage();
        },
        mounted() {
            setTimeout(() => { 
                    const scripts = [
                    "/js/main.js"
                ];
                scripts.forEach(script => {
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag); 
                    }
                });
            }, 3000)
        }
    }
</script>

<style lang="scss" scoped>
div {
    margin:0 auto;
}
</style>