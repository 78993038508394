<template>
    <div>
        
		<!-- header template is included here -->
		<Header 
            :menus="menus"
            :params="params"
            :isLoadingBlade="isLoadingBlade"
            v-if="params"
        />

        <section class="ftco-section ftco-no-pt ftco-no-pb bg-light" v-if="record">
			<div class="container">
				<div class="row no-gutters">
					<div class="col-md-7 p-md-5 img img-2 mt-5 mt-md-0">

                        <div 
                            class="home-slider owl-carousel js-fullheight" 
                            v-if="record.images && record.images.length > 0"
                        >
                            <div 
                                v-for="(image, index) in record.images"
                                :key="index"
                            >
                            <img :src="image.url"  style="height: 400px;" />
                            <!-- <span>{{ image.url }}</span> -->
                            </div>
                        </div>
					</div>
					<div class="col-md-5 wrap-about py-4 py-md-5 ftco-animate">
                        <div class="heading-section mb-5">
                            <div class="pl-md-5">
                                <span class="subheading mb-2">Welcome to We Love To Share</span>
                                <h2 class="mb-2">{{ record.name }}</h2>
                            </div>
                        </div>
	                    <div class="pl-md-5">
							<p>{{ record.description }}</p>
							<div class="founder d-flex align-items-center mt-5">
								<div class="text" v-if="! isUser">
                                    <hr>
									<h3 class="mb-0">
                                        Benefactor's Contact Info
                                    </h3>
									<span class="position" style="font-size: 18px;">
                                        <a :href="'mailto:' + record.user.email" v-if="record.user && record.user.email" ref="emailButton">
                                            <i class="fa fa-envelope" aria-hidden="true"></i> 
                                            - {{ record.user.email }} 
                                        </a>
                                        
                                        <br>

                                        <span v-if="record.whatsapp">
                                            <i class="fa fa-whatsapp" aria-hidden="true"></i> - 
                                            <a :href="'https://api.whatsapp.com/send?phone=' + replaceAll(record.whatsapp, '+', '')">Chat Now</a>
                                        </span>
                                    </span>
								</div>
							</div>

                            <div v-if="isNotUser">
                                <br><br>
                                <a
                                    v-if="canEngageItem"
                                    href="javascript:void(0)"
                                    class="btn btn-primary btn-outline-primary"
                                    @click="engageItem(record.id, 'receiver')"
                                >
                                    {{ isLoading ? 'Please Wait...' : 'Get Item Now' }}
                                </a>

                                <a
                                    v-if="! canEngageItem && ! itemInTransit && record && record.status"
                                    href="javascript:void(0)"
                                    class="btn btn-primary btn-outline-primary"
                                    disabled
                                >
                                    Item {{ replaceAll(record.status, '-', ' ') }}
                                </a>

                                <a
                                    v-if="itemInTransit"
                                    href="javascript:void(0)"
                                    class="btn btn-primary btn-outline-primary"
                                    @click="engageItem(record.id, 'shared')"
                                >
                                    {{ isLoading ? 'Please Wait...' : 'I\'ve received Item' }}
                                </a>
                            </div>

                            <div v-if="isUser">
                                <br><br>
                                <a
                                    v-if="isItemEngaged"
                                    href="javascript:void(0)"
                                    class="btn btn-primary btn-outline-info"
                                    @click="engageItem(record.id, 'send')"
                                >
                                    {{ isLoading ? 'Please Wait...' : 'Dispatch Item Now' }}
                                </a>
                                &nbsp;&nbsp;
                                <!-- <a
                                    v-if="isItemEngaged"
                                    href="javascript:void(0)"
                                    class="btn btn-primary btn-outline-primary"
                                    @click="engageItem(record.id, 'giver')"
                                >
                                    {{ isLoading ? 'Please Wait...' : 'Release Item Now' }}
                                </a> -->
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>

        <div id="ftco-loader" class="show fullscreen" v-if="isLoadingBlade">
            <svg class="circular" width="48px" height="48px">
                <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/>
                <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/>
            </svg>
        </div>

		<!-- Footer template is included here -->
		<Footer 
            :params="params"
        />
		
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import moment from 'moment'
    import swal from 'sweetalert2'

    export default {
        name: 'SharedItemDetailsPage',
        components: {
            Header,
            Footer
        },
        data() {
            return {
                id: this.$route.params.slug,
                isLoadingBlade: false,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                record: {},
                isLoading: false
            }
        },
        computed: {
            ...mapGetters(['getsiteName', 'userEmail']),
            isUser() {
                let email = this.record && this.record.user && this.record.user.email ? this.record.user.email : null;
                return (this.userEmail == email);
            },
            isNotUser() {
                let email = this.record && this.record.user && this.record.user.email ? this.record.user.email : null;
                return (this.userEmail != email);
            },
            isItemEngaged() {
                return this.record && (this.record.status == 'engaged');
            },
            canEngageItem() {
                return this.isNotUser && ! this.isItemEngaged && this.record && this.record.is_eligible;
            },
            itemInTransit() {
                return this.record.status == 'in-transit';
            }
        },
        methods: {
            ...mapActions(['getSharedItemDetails', 'engageSharedItem']),
            engageItem(id, option) {
                if(this.userEmail) {
                    this.errorPage = false;
                    this.isLoading = true;
                    let data = { id: id, email: this.userEmail, option: option };
                    this.engageSharedItem(data).then((res) => {// engage shared items
                        this.isLoading = false;
                        if(res.status) { // set data
                            swal.fire('Item Engaged!!!', res.msg, 'success');
                            this.loadSharedItem();
                            this.$refs.emailButton.click(); // open email app
                        } else {                        
                            swal.fire('Error Occured!!!', res.msg, 'error');
                        }
                    }).
                    catch(() => {
                        this.isLoading = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    });
                } else {
                    window.location.href='/login';// redirect to login
                }                
            },
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
            },
            formatTime(date) {
                return moment(date).format("MMMM Do, YYYY");
            },
            loadSharedItem() {// get shared items
                this.errorPage = false;
                this.isLoadingBlade = true;
                this.getSharedItemDetails({ id: this.id, email: this.userEmail }).then((res) => {// get shared items
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.record = data.record;// set item
                setTimeout(() => { 
                    this.isLoadingBlade = false; 
                    this.removeLoader();
                }, 1000);
            },
            removeLoader() {
                var loader = function() {
                    setTimeout(function() { 
                        if($('#ftco-loader').length > 0) {
                            $('#ftco-loader').removeClass('show');
                        }
                    }, 1);
                };
                loader();
            },
            loadScripts() {
                var contentWayPoint = function() {
                    var i = 0;
                    $('.ftco-animate').waypoint( function( direction ) {
                        if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {                            
                            i++;
                            $(this.element).addClass('item-animate');
                            setTimeout(function(){
                                $('body .ftco-animate.item-animate').each(function(k){
                                    var el = $(this);
                                    setTimeout( function () {
                                        var effect = el.data('animate-effect');
                                        if ( effect === 'fadeIn') {
                                            el.addClass('fadeIn ftco-animated');
                                        } else if ( effect === 'fadeInLeft') {
                                            el.addClass('fadeInLeft ftco-animated');
                                        } else if ( effect === 'fadeInRight') {
                                            el.addClass('fadeInRight ftco-animated');
                                        } else {
                                            el.addClass('fadeInUp ftco-animated');
                                        }
                                        el.removeClass('item-animate');
                                    },  k * 50, 'easeInOutExpo' );
                                });                                
                            }, 100);                            
                        }
                    } , { offset: '95%' } );
                };
                contentWayPoint();
            },
            loadSliderScripts() {
				var carousel = function() {
					$('.home-slider').owlCarousel({
					loop:true,
					autoplay: true,
					margin:0,
					animateOut: 'fadeOut',
					animateIn: 'fadeIn',
					nav:true,
					dots: true,
					autoplayHoverPause: false,
					items: 1,
					navText : ["<span class='ion-ios-arrow-back'></span>","<span class='ion-ios-arrow-forward'></span>"],
					responsive:{
					0:{
						items:1
					},
					600:{
						items:1
					},
					1000:{
						items:1
					}
					}
					});

					$('.carousel-testimony').owlCarousel({
						center: true,
						loop: true,
						items:1,
						margin: 30,
						stagePadding: 0,
						nav: false,
						navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
						responsive:{
							0:{
								items: 1
							},
							600:{
								items: 2
							},
							1000:{
								items: 3
							}
						}
					});

					$('.carousel-stories').owlCarousel({
					loop:true,
					autoplay: true,
					autoHeight: false,
					margin:30,
					nav:true,
					dots: false,
					autoplayHoverPause: false,
					items: 1,
					navText : ["<p><span class='fa fa-chevron-left'></span></p>","<p><span class='fa fa-chevron-right'></span></p>"],
					responsive:{
					0:{
						items:1
					},
					600:{
						items:1
					},
					1000:{
						items:1
					}
					}
					});

				};
				carousel();
			}
        },
        created() {
            this.loadSharedItem();
        },
        mounted() {
            setTimeout(() => { 
                    const scripts = [
                    "/js/main.js"
                ];
                scripts.forEach(script => {
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag); 
                    }
                });

                this.loadScripts();
                this.loadSliderScripts();
            }, 2000)
        }
    }
</script>

<style lang="scss" scoped>

</style>